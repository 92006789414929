import * as envsetting from '../../config/envsetting'
import axios from 'axios'

// export const currentReleaseType = envsetting.currentReleaseType

export const releaseType = {
  localhost: 1,
  interTest: 2,
  release: 3
}
// 组织树类型
export const treeType = {
  collage: 1,
  department: 3,
  courseType: 35,
  class: 31
}
export const getBeforeTime = function (nowTime, beforeDays) {
  let a = new Date(nowTime)
  a = a.valueOf()
  const b = a - beforeDays * 24 * 60 * 60 * 1000
  const beforeTime = new Date(b)

  return beforeTime
}
// 测试环境（暂时未使用）
// export const baseUrl = (function () {
//   console.log(currentReleaseType) // 3
//   if (currentReleaseType === releaseType.localhost) {
//     return 'http://qinfang.test.szzcloud.club'
//   }
//   if (currentReleaseType === releaseType.interTest) { return 'https://cloud.xxx.com' }
//   if (currentReleaseType === releaseType.release) {
//     return 'http://qinfang.test.szzcloud.club' // 琴房
//     // return 'http://qinfang.szzcloud.club' // 琴房
//   }
// })()

// 生产环境（暂时未使用）
// export const baseUrl = (function () {
//   if (currentReleaseType === releaseType.localhost) { return 'http://localhost:7000' }
//   // return `https://weige.zjnu.edu.cn`;
//   if (currentReleaseType === releaseType.interTest) { return 'https://cloud.xxx.com' }
//   if (currentReleaseType === releaseType.release) { return envsetting.releaseBaseUrl }
// })()

// export const baseUrlApi = baseUrl + '/api'
// export const baseUrlApiConfig = baseUrl + '/demo'
export const keyAccessToken = 'accessToken'
export const keyUserInfo = 'userInfo'
export const keyConfigInfo = 'configInfo'
export const keyShowWizard = 'showWizard'
export const keyMsgBadge = 'msgBadge'
export const keyPasswordCheck = 'passwordCheck'
export const keyFace = 'face'
export const keyH5Config = 'h5Config'
export const automaticLogin = 'automaticLogin'
export const remember = 'remember'
export const user = 'user'
export const passworsdSimpleness = 'passworsdSimpleness'
export const globlePagesize = 10
export const recentUseMenuList = 'recentUseMenuList'

// export const uploadUrl = `${baseUrl}/api/upload.upload`

export const serverName = 'http://' + envsetting.serverName
export const cloudParkingUrl = 'https://' + envsetting.serverName
export const cloudPayUrl = 'https://' + envsetting.payServerName
export const iotUrl = envsetting.iotServerName
export const chargeUrl = envsetting.chargeServeUrl
export const codeUrl = envsetting.codeUrl + '/get_coupon/'
export const emqxServerName = envsetting.emqxServerName + '/mqtt'
export const cityCloudUrl = envsetting.cityCloudUrl
export const adverType = envsetting.adFlag

// export const currentReleaseType = envsetting.currentReleaseType
export const RespMsg = {
  // 成功
  OK: {
    code: 10000,
    msg: 'Success'
  },
  // 重复插入数据
  DataExists: {
    code: 10001,
    msg: 'Data exists'
  },
  // 外键冲突
  KeyStillReferenced: {
    code: 10002,
    msg: 'Key still referenced.'
  },
  // //验证码错误
  // VerifyCodeError : {
  //     code: 10009,
  //     msg: 'Verify code error.'
  // },

  ProductInfoErr: {
    code: 10100,
    msg: 'Product info error.'
  },

  // 没有登录
  Unauthorized: {
    code: 11000,
    msg: 'Unauthorized, needs login.'
  },
  // 没有权限
  Forbidden: {
    code: 11001,
    msg: 'Forbidden, no right to access.'
  },
  // 无法登录
  LoginFailed: {
    code: 11002,
    msg: 'Login failed, username or password error.'
  },
  // 账号被锁定
  UserLocked: {
    code: 11003,
    msg: 'Account was locked.'
  },
  // 数据已被后续流程使用，不允许删除或修改
  DataLocked: {
    code: 11004,
    msg: 'Data has been used, not allow to be change.'
  },
  // 业务流程错误
  InvalidFlow: {
    code: 11005,
    msg: 'Invalid flow.'
  },
  // 业务流程错误
  HandlerNoCap: {
    code: 11006,
    msg: 'waste handler has no capability.'
  },
  // 内部错误
  InternalServerError: {
    code: 11100,
    msg: 'Internal server error.'
  },
  // postgresql访问错误
  PostgresqlServerError: {
    code: 11101,
    msg: 'Postgresql error.'
  },
  // redis访问错误
  RedisServerError: {
    code: 11102,
    msg: 'Redis error.'
  },
  // api参数错误
  ParamsError: {
    code: 11103,
    msg: 'Api params error.'
  },
  // 资源未找到
  ResourceNotFound: {
    code: 11104,
    msg: 'Resource not be found.'
  },

  // 接口已废弃
  InterfaceDisabled: {
    code: 11105,
    msg: 'Interface disabled.'
  },
  scan_channel: {
    code: 11118,
    msg: '请扫通道码'
  },
  abnormal_vh: {
    code: 11119,
    msg: '车辆异常'
  },
  scan_inner: {
    code: 11120,
    msg: '请扫场内码'
  },
  Sign_err: {
    code: 11126,
    msg: '签名错误'
  },
  Verify_sign_err: {
    code: 11127,
    msg: '验签失败'
  },
  SidErr: {
    code: 11128,
    msg: 'sid有误'
  },

  // 80000 开始为parkclietn的消息信息
  SidNotExit: {
    code: 80000,
    msg: '云编号错误'
  },
  ParkTimeErr: {
    code: 80001,
    msg: '停车信息有误'
  },
  FeeRegErr: {
    code: 80002,
    msg: '收费规则错误'
  },
  FeeSignErr: {
    code: 80003,
    msg: '计算费用签名失败'
  },
  FeeCalcErr: {
    code: 80004,
    msg: '费用计算出错'
  },
  PlateNumErr: {
    code: 80005,
    msg: '车牌有误'
  },
  DiscountRegErr: {
    code: 80006,
    msg: '打折规则出错'
  },
  ChanellNotExistPlate: {
    code: 80007,
    msg: '通道未识到别到此车辆'
  },
  ChanellWriteOrderErr: {
    code: 80008,
    msg: '通道无此车牌数据'
  },
  PlateInErr: {
    code: 80009,
    msg: '无进场记录'
  },
  ParkLeave: {
    code: 80014,
    msg: '车场离线'
  },
  NetworkError: {
    code: 9999001,
    msg: '网络错误'
  },
  CustomError: {
    code: 99999,
    msg: '自定义错误'
  }
}
export const resCode = {
  OK: 10000,
  DataExists: 10001,
  KeyStillReferenced: 10002,
  Unauthorized: 11000,
  Forbidden: 11001,
  UsernameorPasswordError: 11002,
  UserBeLocked: 11003,
  InternalServerError: 11100,
  PostgresqlError: 11101,
  RedisError: 11102,
  ApiParamsError: 11103,
  ResourceNotbeFound: 11104,
  InterfaceDisabled: 11105,
  Failed: 99999,
  used: 11004
}

export const resMSG = {
  10001: '数据重复',
  10002: '外键冲突',
  10100: 'product info error',
  11000: '请重新登录',
  11001: '没有权限',
  11002: '请核对登录信息',
  11003: '用户已锁定',
  11004: '数据已被后续流程使用，不允许删除或修改',
  11005: '业务流程错误',
  11006: '业务流程错误',
  11100: '内部错误',
  11101: 'Postgre错误',
  11102: 'Redis错误',
  11103: 'API参数错误',
  11104: '资源未找到',
  11105: '接口废弃',
  11118: '请扫通道码',
  11119: '车辆异常',
  11120: '请扫场内码',
  11126: '签名错误',
  11127: '验签失败',
  99999: '未知错误',
  80000: '云编号错误',
  80001: '停车信息有误',
  80002: '收费规则错误',
  80003: '计算费用签名失败',
  80004: '费用计算出错',
  80005: '车牌有误',
  80006: '打折规则出错',
  80007: '通道未识别到此车辆',
  80008: '通道无此车牌数据',
  80009: '无进场记录',
  80014: '车场离线'
}

export const editWord = {
  addSuccess: '添加成功',
  addError: '添加失败',
  modifySuccess: '修改成功',
  modifyError: '修改失败',
  deleteSuccess: '删除成功',
  deleteError: '删除失败'
}

// 时间格式化，并且默认开始时间是00:00:00，结束时间是23:59:59
export const timeFormat = function (time, flag) {
  let date
  if (time) {
    date = new Date(time)

    const seperator1 = '-'
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let h = date.getHours()
    let min = date.getMinutes()
    let s = date.getSeconds()
    if (m >= 1 && m <= 9) {
      m = '0' + m
    }
    if (d >= 1 && d <= 9) {
      d = '0' + d
    }
    if (h >= 0 && h <= 9) {
      h = '0' + h
    }
    if (min >= 0 && min <= 9) {
      min = '0' + min
    }
    if (s >= 0 && s <= 9) {
      s = '0' + s
    }
    // 规定1代表开始时间，2代表结束时间
    if (flag === 1) {
      return y + seperator1 + m + seperator1 + d + ' ' + '00:00:00' // 格式：2018-12-18 00:00:00
    } else if (flag === 2) {
      return y + seperator1 + m + seperator1 + d + ' ' + '23:59:59' // 格式：2018-12-18 23:59:59
    } else if (flag === 3) {
      return h + ':' + min + ':' + s
    }
  } else {
    return ''
  }
}

// 表格中显示的时间格式化
export const tableTimeFormat = function (time, type) {
  let date
  if (time) {
    date = new Date(time)
    const seperator1 = '-'
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let h = date.getHours()
    let min = date.getMinutes()
    let s = date.getSeconds()
    if (m >= 1 && m <= 9) {
      m = '0' + m
    }
    if (d >= 0 && d <= 9) {
      d = '0' + d
    }
    if (h >= 0 && h <= 9) {
      h = '0' + h
    }
    if (min >= 0 && min <= 9) {
      min = '0' + min
    }
    if (s >= 0 && s <= 9) {
      s = '0' + s
    }
    if (type) {
      return y + seperator1 + m + seperator1 + d // 格式：2018-12-18
    } else {
      return (
        y + seperator1 + m + seperator1 + d + ' ' + h + ':' + min + ':' + s
      ) // 格式：2018-12-18 02:11
    }
  } else {
    return ''
  }
}
//
export const tableTimeFormatNoS = function (time) {
  let date
  if (time) {
    date = new Date(time)
    const seperator1 = '-'
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let h = date.getHours()
    let min = date.getMinutes()
    let s = date.getSeconds()
    if (m >= 1 && m <= 9) {
      m = '0' + m
    }
    if (d >= 0 && d <= 9) {
      d = '0' + d
    }
    if (h >= 0 && h <= 9) {
      h = '0' + h
    }
    if (min >= 0 && min <= 9) {
      min = '0' + min
    }
    if (s >= 0 && s <= 9) {
      s = '0' + s
    }

    return y + seperator1 + m + seperator1 + d + ' ' + h + ':' + min
  } else {
    return ''
  }
}

export const checkActive = function (router, path) {
  if (!Array.isArray(router)) return
  for (let i = 0; i < router.length; i++) {
    if (router[i].allow && Array.isArray(router[i].children)) {
      for (let j = 0, ls1 = router[i].children; j < ls1.length; j++) {
        if (ls1[j].allow) {
          if (path === ls1[j].path) {
            return {
              bigMenu: router[i],
              midMenu: ls1[j]
            }
          }
          if (Array.isArray(ls1[j].children)) {
            for (let k = 0, cld = ls1[j].children; k < cld.length; k++) {
              if (cld[k].allow && path === cld[k].path) {
                return {
                  bigMenu: router[i],
                  midMenu: ls1[j]
                }
              }
            }
          }
        }
      }
    }
  }
}

export const excelDate2TxtDate = function (numb) {
  console.log(numb)
  var second = 25569
  var dayTimestamp = 24 * 60 * 60 * 1000
  // 把五位数的numb转化为时间戳类型，单位是毫秒
  var cTime = (numb - second) * dayTimestamp
  // 如果numb为空或者不为时间类型，则原数据赋值
  console.log(cTime)
  if (cTime.toString() === 'NaN' || cTime <= 0) {
    return numb
  } else {
    // 转化为时间格式
    return tableTimeFormat(cTime)
  }
}

export const delEmpty = function (obj) {
  if (Array.isArray(obj)) {
    if (obj.length) {
      for (let i = 0; i < obj.length; i++) {
        delEmpty(obj[i])
      }
    }
  } else {
    if (obj.children.length) {
      for (let i = 0; i < obj.children.length; i++) {
        delEmpty(obj.children[i])
      }
    } else {
      delete obj.children
    }
  }
}

export const checkClassType = function (obj, id, type) {
  // 列表，id，类型
  if (!id && id !== 0) return false
  if (Array.isArray(obj)) {
    if (obj.length) {
      for (let i = 0; i < obj.length; i++) {
        const res = checkClassType(obj[i], id, type)
        if (res) {
          return true
        }
      }
    }
  } else {
    if (obj.id === id) {
      return obj.type === type
    }
    if (Array.isArray(obj.children)) {
      for (let i = 0; i < obj.children.length; i++) {
        const res = checkClassType(obj.children[i], id, type)
        if (res) {
          return true
        }
      }
    }
  }
}

export const fmtDate = function (oDate, format) {
  // fmtDate('2017/12/12 09:08:08', 'yyyy-MM-dd hh:mm:ss')
  if (oDate === '' || oDate == null) {
    format = ''
  } else {
    // var oDate2 = oDate.replace(/-/g, '/')
    var thisdate = new Date(oDate)
    var date = {
      'M+': thisdate.getMonth() + 1,
      'd+': thisdate.getDate(),
      'h+': thisdate.getHours(),
      'm+': thisdate.getMinutes(),
      's+': thisdate.getSeconds(),
      'q+': Math.floor((thisdate.getMonth() + 3) / 3),
      'S+': thisdate.getMilliseconds()
    }
    if (/(y+)/i.test(format)) {
      format = format.replace(
        RegExp.$1,
        (thisdate.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }
    for (var k in date) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? date[k]
            : ('00' + date[k]).substr(('' + date[k]).length)
        )
      }
    }
  }
  return format
}

export const changeMonths = function (v, n) {
  const changeCate = new Date(v.getTime())
  const zt = new Date(v.getTime())

  zt.setDate(zt.getDate() + 1)
  const islastday = zt.getMonth() !== changeCate.getMonth()

  changeCate.setMonth(changeCate.getMonth() + n)
  if (changeCate.getDate() === v.getDate()) {
    if (islastday) {
      const m = changeCate.getMonth()
      changeCate.setDate(changeCate.getDate() + 1)
      if (m === changeCate.getMonth()) {
        changeCate.setDate(1)
        changeCate.setMonth(changeCate.getMonth() + 1)
        changeCate.setDate(0)
      } else {
        changeCate.setDate(changeCate.getDate() - 1)
      }
    }
  } else {
    changeCate.setDate(0)
  }
  return changeCate
}

export const formatTimeYMDInterval = (date, interval) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return formatNumber(year) + interval + formatNumber(month) + interval + formatNumber(day)
}

export const dateFormat = function (date, fmt) {
  if (!date) {
    return ''
  }
  if (!fmt) {
    fmt = 'yyyy-MM-dd HH:mm:ss'
  }

  let ret
  const opt = {
    'y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'h+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    };
  };
  return fmt
}

export const formatDate = function (dateStr) {
  if (!dateStr) {
    return ''
  }

  if (/^\d{2,4}(-|\/)\d{1,2}(-|\/)\d{1,2}/.test(dateStr)) {
    let gmtTime
    if (/^\d{2,4}-\d{1,2}-\d{1,2}/.test(dateStr)) {
      gmtTime = /\.\d+Z$/.test(dateStr)
      dateStr = dateStr.replace(/-/g, '/').replace(/T/, ' ').replace(/\.\d+Z$/, '').replace(/\.\d+\+/, '+')
        .replace(/\+\d{2}:\d{2}$/, '')
    }

    const date = new Date(dateStr)
    if (gmtTime && date) {
      date.setMinutes(date.getMinutes() - new Date().getTimezoneOffset())
    }
    return date
  } else {
    return ''
  }
}

/**
 * 格式化Excel表格中存储的时间
 * @param {Number} num:excel存储的数字
 */
export function formatExcelDate (num) {
  num = Number(num)// 强制类型转化，以防传来的值是字符串
  let millisecond = 0// 转化后的毫秒数
  if (num > 60) {
    millisecond = (num - 25569) * 60 * 60 * 24 * 1000
  } else {
    millisecond = (num - 25568) * 60 * 60 * 24 * 1000
  }
  return new Date(millisecond)// 根据转化后的毫秒数获取对应的时间
}

/**
 * 格式化Excel表格中存储的时间
 * @param {Number} num:excel存储的数字
 * @param {String} format: 年月日的间隔符，默认为'-'
 */
export function formatExcelTime (num, format = '-') {
  const date = formatExcelDate(num)
  const yy = date.getFullYear()
  const mm = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return yy + format + mm + format + dd// 返回格式化后的日期
}

/**
 * 校验密码
 * @param {String} password:密码
 */
export function checkPassword (password) {
  if (!password) {
    return false
  }

  const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{6,16}$/
  if (regex.test(password)) {
    return true
  } else {
    return false
  }
}

/**
 * 输入密码限制
 * @param {String} password:密码
 */
export function passwordInputLimit (password) {
  if (!password) {
    return password
  }

  return password.replace(/[^a-zA-Z0-9\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]+/g, '')
}

export const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export const statisticDateType = { // 新加统计类别
  month: 3,
  week: 2,
  date: 1
}

export const formatHour = function (hour) {
  return `${hour}:00-${hour + 1}:00`
}

export const formatMin2Hour = function (min) {
  return ((min || 0) / 60).toFixed(2).replace(/\.00$/, '')
}

export const formatWeek = function (hour) {
  return `${hour}:00-${hour + 1}:00`
}

export const formatYearMonth = function (year, month) {
  return year + '年' + month + '月'
}

export const getWeekStart = function (dateTime, n = 0) { // 上周一  传 -1，上上周一 传-2， 下周一传1， 默认 0 当前周
  const d = dateTime ? new Date(dateTime) : new Date()
  const day = d.getDay()
  let pre
  if (day > 0) {
    pre = day - 1
  } else {
    pre = 6
  }
  pre = pre + n * 7
  d.setDate(d.getDate() - pre)
  return d
}

export const getWeekStartEnd = function (dateTime) {
  const start = getWeekStart(dateTime)
  const end = new Date(start.getTime() + 24 * 3600000 * 6)
  return {
    weekStart: start,
    weekEnd: end
  }
}

export const getMonthStartEndByDateTime = function (datetime) {
  const d = new Date(datetime)
  const year = d.getFullYear()
  const month = d.getMonth()
  const start = new Date(year, month, 1)
  let end
  if (month < 11) {
    end = new Date(year, month + 1, 0)
  } else {
    end = new Date(year + 1, 0, 0)
  }
  return {
    monthStart: start,
    monthEnd: end
  }
}

export const getYearWeekIndex = function (date) {
  // 新的一年的第一个星期一开始算第一周
  const curr = date ? new Date(date) : new Date()
  const year = curr.getFullYear(); const month = curr.getMonth(); const d = curr.getDate()
  const yearStart = new Date(year, month, d)
  yearStart.setMonth(0)
  yearStart.setDate(1)
  let jumpDay = 0
  const firstDayOfWeek = yearStart.getDay()
  if (firstDayOfWeek >= 1) {
    jumpDay = 7 - firstDayOfWeek
  } else {
    jumpDay = -1
  }
  const YearFirstMonday = new Date(year, 0, 1 + jumpDay)
  const spanMillSeconds = new Date(year, month, d).valueOf() - YearFirstMonday.valueOf()
  const totalDays = Math.ceil(spanMillSeconds / (24 * 60 * 60 * 1000))
  if (totalDays <= 0) {
    return {
      year: year - 1,
      week: 52
    }
  } else {
    const week = Math.ceil(totalDays / 7)
    return {
      year: year,
      week: week
    }
  }
}

export const getMonthDays = function (dateTime) {
  const d = new Date(dateTime)
  const year = d.getFullYear(); const month = d.getMonth() + 1
  const lastMonthDate = new Date(year, month, 0)
  return lastMonthDate.getDate()
}

export const getClassPath = function (orgTree, id, path = '') {
  console.log('id, path', id, path)
  if (Array.isArray(orgTree) && orgTree.length) {
    for (let i = 0; i < orgTree.length; i++) {
      let pt = ''
      const item = orgTree[i]
      if (path) pt += path + '/'
      pt += item.name
      if (item.id === id) {
        return pt
      }
      if (Array.isArray(item.children) && item.children.length) {
        const result = getClassPath(item.children, id, pt)
        if (result) {
          return result
        }
      }
    }
  } else if (orgTree) {
    let pt = ''
    if (path) pt += path + '/'
    pt += orgTree.name
    if (orgTree.id === id) {
      return pt
    }
    if (Array.isArray(orgTree.children) && orgTree.children.length) {
      const result = getClassPath(orgTree.children, id, pt)
      if (result) {
        return result
      }
    }
  }
}
export const getClassList = function (orgTree, currentList) {
  console.log('orgtree', orgTree, currentList)
  const list = []
  let org = Object.assign([], orgTree)
  for (let i = 0; i < currentList.length; i++) {
    org = findObjById(org, currentList[i])
  }
  if (org) {
    getClasses(org, list)
  }
  console.log(list)
  return list
  function findObjById (orgTree, id) {
    if (Array.isArray(orgTree)) {
      for (let i = 0; i < orgTree.length; i++) {
        const item = orgTree[i]
        if (id === item.id) {
          return item
        }
        if (Array.isArray(orgTree.children) && orgTree.children.length) {
          return findObjById(orgTree.children, id)
        }
      }
    } else if (typeof orgTree === 'object') {
      if (id === orgTree.id) {
        return orgTree
      }
      if (Array.isArray(orgTree.children) && orgTree.children.length) {
        return findObjById(orgTree.children, id)
      }
    }
  }
  function getClasses (orgTree, list) {
    console.log('orgTree', orgTree)
    if (Array.isArray(orgTree) && orgTree.length) {
      for (let i = 0; i < orgTree.length; i++) {
        const item = orgTree[i]
        if (item.type === 31) {
          list.push(item.id)
        }
        if (Array.isArray(item.children) && item.children.length) {
          getClasses(item.children, list)
        }
      }
    } else if (orgTree) {
      if (orgTree.type === 31) {
        list.push(orgTree.id)
      }
      if (Array.isArray(orgTree.children) && orgTree.children.length) {
        getClasses(orgTree.children, list)
      }
    }
  }
}

export const getOrgName = function (orgTree, id) {
  if (Array.isArray(orgTree) && orgTree.length) {
    for (let i = 0; i < orgTree.length; i++) {
      const item = orgTree[i]
      if (item.id === id) {
        return item.name
      }
      if (Array.isArray(item.children) && item.children.length) {
        const name = getOrgName(item.children, id)
        if (name) {
          return name
        }
      }
    }
  } else if (orgTree) {
    if (orgTree.id === id) {
      return orgTree.name
    }
    if (Array.isArray(orgTree.children) && orgTree.children.length) {
      const name = getOrgName(orgTree.children, id)
      if (name) {
        return name
      }
    }
  }
}

export const simpleDictId = [
  {
    name: '作息时间',
    id: 1,
    unit: '节'
  },
  {
    name: '考试场次',
    id: 2,
    unit: '场'
  },
  {
    name: '预留',
    id: 3,
    unit: '场'
  }
]
export const simpleDictAllow = {
  allow: 2,
  notAllow: 1
}
export const courseBaseTypeList = [
  {
    label: '排课',
    value: 'course'
  },
  {
    label: '预留',
    value: 'obligate'
  }
]

export const courseSimpleBook = {
  base_type: {
    book: 'book',
    obligate: 'obligate', // 预留都是
    course: 'course' // 老师或学生课程
  },
  assign: {
    default: 0, // 不指定学员
    class: 1, // 指定班级
    user: 2 // 指定人员
  },
  fixed: {
    default: 0, // 不固定room
    user_per_room: 1 // 一人一个琴房
  }

}

export const getBuildingFloor = (buildingData) => {
  const buildingFloor = {}
  for (const s in buildingData) {
    const floorList = []
    buildingFloor[s] = {
      value: buildingData[s].value,
      name: buildingData[s].name,
      floor: floorList
    }

    const floor = buildingData[s].floor
    for (const c in floor) {
      floorList.push({
        value: floor[c].value,
        name: floor[c].name
      })
    }
  }
  return buildingFloor
}

export const getRoomList = (buildingData) => {
  const roomList = []
  for (const s in buildingData) {
    const floor = buildingData[s].floor

    for (const c in floor) {
      floor[c].room.forEach(item => {
        roomList.push({
          id: item.id,
          name: item.name
        })
      })
    }
  }
  return roomList
}

export const getBuildingCascader = (buildingData) => {
  const options = []
  for (const s in buildingData) {
    const floorList = []
    options.push({
      value: buildingData[s].value,
      label: buildingData[s].name,
      children: floorList
    })

    const floor = buildingData[s].floor
    for (const c in floor) {
      const roomList = []
      floorList.push({
        value: floor[c].value,
        label: floor[c].name,
        children: roomList
      })

      floor[c].room.forEach(item => {
        roomList.push({
          value: item.id,
          label: item.name
        })
      })
    }
  }
  return options
}

export const direction = {
  in: 1,
  out: 2
}

export const sessionStorageKey = {
  billOpenid: 'bill_openid',
  bindBillType: 'bind_bill_type',
  saveBillAuthCode: 'save_bill_auth_cod',
  billWxSelfInfo: 'bill_wx_self_info',
  applyBillInfo: 'apply_bill_info'
}

export const getUrlObjBase = (urlObj) => {
  let url = urlObj.protocol + '//' +
    (urlObj.hostname || urlObj.host)
  if (urlObj.port) {
    url += ':' + urlObj.port
  }
  url += urlObj.pathname
  if (window.location.hash && window.location.hash.indexOf('#') === 0) {
    url += '#'
  }
  return url
}

export const getCodeObtainUrl = (targetObjUrl, appId, isWx) => {
  const callUrl = getUrlObjBase(targetObjUrl) + '/codeObtain?url=' + encodeURIComponent(targetObjUrl.href)
  let url
  if (isWx) {
    url = 'https://open.weixin.qq.com/connect/oauth2/authorize' +
      '?appid=' + appId +
      '&redirect_uri=' + encodeURIComponent(callUrl) +
      '&response_type=code' +
      '&scope=snsapi_base' +
      '&state=1#wechat_redirect'
  } else {
    url = 'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm' +
      '?app_id=' + appId +
      '&scope=auth_base' +
      '&redirect_uri=' + encodeURIComponent(callUrl)
  }
  return url
}

export const payWay = {
  weichat: 1,
  alipay: 2
}
export const userAgentInfo = {
  wx: 'wx',
  zfb: 'zfb'
}

export const payType = {
  wx: 1, // 微信
  zfb: 2, // 支付宝
  paypal: 3, // paypal
  union: 4, // 银联
  xcx: 5, // 小程序
  bjyz: 21, // 北京云纵微信
  bjyz_zfb: 22, // 北京云纵【支付宝】
  pp: 31, // PP云支付【微信】
  pp_zfb: 32, // PP云支付【支付宝】
  abc: 41, // 农行云支付【微信】
  abc_zfb: 42, // 农行云支付【支付宝】
  lanzhoubank: 51, // 兰州银行支付【微信】
  lanzhoubank_zfb: 52, // 兰州银行支付【支付宝】
  ningbobank: 61, // 宁波银行支付【微信】
  ningbobank_zfb: 62 // 宁波银行支付【支付宝】
}

const payPlatform = {
  yunzong: 20,
  pp: 30,
  abc: 40,
  lanzhou: 50
}

export const formatTime = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}
export const format = function (thisdate, format) {
  if (typeof thisdate === 'string') {
    thisdate = thisdate.replace(/-/g, '/')
    if (/\.\d+Z$/.test(thisdate)) {
      console.log('thisdate', thisdate)
      thisdate = thisdate.replace('T', ' ').replace(/\.\d+Z$/, '')
      const timezone = new Date().getTimezoneOffset()
      thisdate = new Date(thisdate)
      thisdate.setMinutes(thisdate.getMinutes() - timezone)
    }
  }

  const d = new Date(thisdate)
  const date = {
    'M+': d.getMonth() + 1,
    'd+': d.getDate(),
    'h+': d.getHours(),
    'm+': d.getMinutes(),
    's+': d.getSeconds(),
    'q+': Math.floor((d.getMonth() + 3) / 3),
    'S+': d.getMilliseconds()
  }
  if (/(y+)/i.test(format)) {
    format = format.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (const k in date) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? date[k] : ('00' + date[k]).substr(('' + date[k]).length)
      )
    }
  }
  return format
}
export const getUrlParam = function (name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') // 构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg) // 匹配目标参数
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null // 返回参数值
}

export const getUserAgent = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i)) {
    return 'wx'
  } else if (ua.match(/AlipayClient/i)) {
    return 'zfb'
  }
}

export const getPayargs = (obj, openid, type, payChannelPlatform) => {
  return new Promise(function (resolve, reject) {
    const userAgent = getUserAgent()
    let path; const pcp = payChannelPlatform || payPlatform.pp
    type = type || localStorage.getItem('pay_type')
    if (userAgent === 'wx') {
      path = 'pay.wx.do_h5'
      if (!type || type === 'undefined') type = parseInt(pcp) + payType.wx
      if (!type) type = payType.wx
    }
    if (userAgent === 'zfb') {
      path = 'pay.zfb.do_h5'
      if (!type || type === 'undefined') type = parseInt(pcp) + payType.zfb
      if (!type) type = payType.zfb
    }
    console.log('type', type)
    console.log(obj, 'util参数')
    axios({
      url: cloudParkingUrl + '/s2p/' + path,
      method: 'POST',
      data: {
        type: type,
        // type: 1,
        code: openid == null ? obj.code : openid,
        sid: obj.sid,
        plate_number: obj.plate_number,
        gross_price: obj.gross_price,
        actually_paid: obj.actually_paid,
        subject: obj.subject,
        start_time: obj.start_time,
        end_time: obj.end_time,
        local_info: obj.local_info,
        sign: obj.sign,
        is_xcx: obj.is_xcx
      },
      header: {
        'content-type': 'application/json' // 默认值
      }
    }).then(res => {
      console.log(res, '' +
        '获取支付')
      const d = res && res.data
      if (d.code === 10000) {
        const payargs = d.payargs
        resolve(payargs)
      } else {
        reject(new Error(JSON.stringify({ code: d.code, msg: d.msg })))
      }
    }).catch(e => {
      reject(e)
    })
  })
}
export const getProp = (variable) => {
  if (window.location.toString().indexOf('?') === -1) {
    return (false)
  }
  var url = decodeURI(decodeURI(window.location)).toString().split('?')[1]
  var row = url.split('&')
  for (var i = 0; i < row.length; i++) {
    var pair = row[i].split('=')
    if (pair[0] === variable) {
      if (pair[1] === 'null') {
        return ''
      } else {
        return decodeURIComponent(pair[1])
      }
    }
  }
  return (false)
}

export const getPayargsMonth = (sidLocal, grossPrice, actuallyPaid, thePayType, subject, sessionStorageLocal, code) => {
  return new Promise(function (resolve, reject) {
    const userAgent = getUserAgent()
    let path
    if (userAgent === 'wx') {
      path = 'pay.wx.do_h5_park_buy'
    }
    if (userAgent === 'zfb') {
      path = 'pay.zfb.do_h5_park_buy'
    }
    console.log('thePayType', thePayType)
    console.log(sessionStorageLocal, 'sessionStorageLocal')
    axios({
      url: chargeUrl + '/uuid/' + path,
      method: 'POST',
      data: {
        sid: sidLocal,
        gross_price: grossPrice,
        actually_paid: actuallyPaid,
        type: thePayType,
        subject: subject,
        business_info: sessionStorageLocal,
        code: code
      },
      header: {
        'content-type': 'application/json' // 默认值
      }
    }).then(res => {
      console.log(res, '' +
        '获取支付')
      const d = res && res.data
      console.log('获取支付d', d)
      if (d.code === 10000) {
        resolve(d.payargs)
      } else {
        console.log('getPayargsMonth-error', d.msg)
        reject(new Error(d.msg))
      }
    }).catch(e => {
      console.log('getPayargsMonth-catch', e)
      reject(e)
    })
  })
}
